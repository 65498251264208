import {
  community,
  subspeciality,
  country,
  expert,
  metatag,
} from "../../js/path";
import _ from "lodash";

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      metatag_id: null,
      activeTab: "all",
      table_header: [],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      items: [
        {
          text: "Back",
          href: "/metatag",
        },
        {
          text: "Data",
        },
      ],
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "name",
          label: "Page Name",
        },
        {
          key: "type",
          label: "meta type",
        },
        {
          key: "edit",
        },
      ],
      country: [],
      community: [],
      subspeciality: [],
      oldForm: {},
      form: {
        name: "",
        type: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
      },
      image_name_url: "",
      image_name: "",
      edit: {
        image_name_url: null,
      },
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData(this.activeTab);
      } else {
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            metatag.fetchMetaTag + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = metatag.fetchMetaTag + "?filter=" + txt;
        }
      } else if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            metatag.fetchMetaTag + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = metatag.fetchMetaTag + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            metatag.fetchMetaTag + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = metatag.fetchMetaTag + "?filter=" + txt;
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchExpert(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = metatag.fetchMetaTag + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.name != "" && responseData.name != null) {
            this.form.name = responseData.name;
          }

          if (responseData.type != "" && responseData.type != null) {
            this.form.type = responseData.type;
          }

          if (
            responseData.meta_description != "" &&
            responseData.meta_description != null
          ) {
            this.form.meta_description = responseData.meta_description;
          }

          if (
            responseData.meta_keywords != "" &&
            responseData.meta_keywords != null
          ) {
            this.form.meta_keywords = responseData.meta_keywords;
          }

          if (
            responseData.meta_title != "" &&
            responseData.meta_title != null
          ) {
            this.form.meta_title = responseData.meta_title;
          }

          if (
            responseData.nationality != null &&
            responseData.nationality != ""
          ) {
            this.form.nationality = responseData.nationality;
          }

          if (
            responseData.thumbnail_url != null &&
            responseData.thumbnail_url != ""
          ) {
            this.form.thumbnail_url = responseData.thumbnail_url;
          }
          if (
            responseData.display_name != null &&
            responseData.display_name != ""
          ) {
            this.form.display_name = responseData.display_name;
          }

          this.edit.image_name_url = responseData.image_name;

          if (responseData.show_in_home) {
            this.form.show_in_home = true;
          }
          if (responseData.visible_in_cases) {
            this.form.visible_in_cases = true;
          }

          if (
            responseData.community_selected != "" &&
            responseData.community_selected != null
          ) {
            this.form.community_selected = responseData.community_selected.map(
              (c) => {
                return {
                  id: c.community_id,
                  title: c.name,
                };
              }
            );
          }

          if (responseData.sub_specialities) {
            this.form.sub_specialities = this.subspeciality.filter((item) => {
              if (responseData.sub_specialities.includes(item.id)) {
                return item;
              }
            });
          }

          this.metatag_id = responseData.id;
          this.oldForm = _.clone(this.form);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = expert.statusUpdate + "/" + id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },
    async updateFlag(id) {
      try {
        let index = this.tableData.data.findIndex((e) => e.id == id);
        const url = expert.flagUpdate + "/" + id;
        const data = await this.postRequest(url, {
          discussion_flag: !this.tableData.data[index].discussion_flag,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },
    async fetchAllCommunity() {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url);
      if (data.status) {
        this.form.community_selected = data.response.data;
      }
    },
    async fetchCommunity(query) {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url + "&search=" + query);
      if (data.status) {
        this.community = data.response.data;
      }
    },
    async fetchNationality() {
      const url = country.fetchAllCountry;
      const data = await this.getRequest(url);
      if (data.status) {
        this.country = data.response.data;
      }
    },
    async fetchSubSpeciality() {
      const url = subspeciality.fetchAllSubSpeciality;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response.data;
        this.subspeciality = responseData;
      }
    },
    async deleteExpert(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = metatag.fetchMetaTag + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreExpert(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = expert.restoreExpert + "/" + id;
        const data = await this.postRequest(url, {});
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = metatag.fetchMetaTag;
        if (this.$route.name == "edit-metacontent") {
          url = metatag.fetchMetaTag + "/" + this.metatag_id;
        }
        let dataAppend = new FormData();

        if (this.form.name) {
          dataAppend.append("name", this.form.name);
        }

        if (this.form.type) {
          dataAppend.append("type", this.form.type);
        }

        if (this.form.meta_title) {
          dataAppend.append("meta_title", this.form.meta_title);
        }

        if (this.form.meta_description) {
          dataAppend.append("meta_description", this.form.meta_description);
        }

        if (this.form.meta_keywords) {
          dataAppend.append("meta_keywords", this.form.meta_keywords);
        }

        if (this.$route.name == "edit-metacontent") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/metatag");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    // tabActive() {
    //    if (this.activeTab == "trash") {
    //       if (this.table_header.includes("delete")) {
    //          let index = this.fields.findIndex(
    //             (item) => item.key == "delete"
    //          );
    //          this.fields[index] = {
    //             key: "restore",
    //          };
    //          let table_index = this.table_header.findIndex(
    //             (item) => item == "delete"
    //          );
    //          this.table_header[table_index] = "restore";
    //       } else {
    //          this.table_header.push("restore");
    //          this.fields.push({
    //             key: "restore",
    //          });
    //       }

    //       if (!this.can("restore-metacontent")) {
    //          let index = this.fields.findIndex(
    //             (item) => item.key == "restore"
    //          );
    //          if (index !== -1) {
    //             this.fields.splice(index, 1);
    //             let table_index = this.table_header.findIndex(
    //                (item) => item == "restore"
    //             );
    //             this.table_header.splice(table_index, 1);
    //          }
    //       }

    //       let index = this.fields.findIndex((item) => item.key == "edit");
    //       if (index !== -1) {
    //          this.fields.splice(index, 1);
    //          let table_index = this.table_header.findIndex(
    //             (item) => item == "edit"
    //          );
    //          this.table_header.splice(table_index, 1);
    //       }
    //    } else {
    //       if (this.table_header.includes("restore")) {
    //          if (!this.can("edit-metacontent")) {
    //             this.fields[6] = {
    //                key: "delete",
    //             };
    //          } else {
    //             this.fields[7] = {
    //                key: "delete",
    //             };
    //          }

    //          let table_index = this.table_header.findIndex(
    //             (item) => item == "restore"
    //          );
    //          this.table_header[table_index] = "delete";
    //       }

    //       if (!this.can("edit-metacontent")) {
    //          let index = this.fields.findIndex((item) => item.key == "edit");
    //          if (index !== -1) {
    //             this.fields.splice(index, 1);
    //             let table_index = this.table_header.findIndex(
    //                (item) => item == "edit"
    //             );
    //             this.table_header.splice(table_index, 1);
    //          }
    //       } else {
    //          if (!this.table_header.includes("edit")) {
    //             this.table_header.push("edit");
    //             this.fields[6] = {
    //                key: "edit",
    //             };
    //          }
    //       }

    //       if (!this.can("delete-metacontent")) {
    //          let index = this.fields.findIndex(
    //             (item) => item.key == "delete"
    //          );
    //          if (index !== -1) {
    //             this.fields.splice(index, 1);
    //             let table_index = this.table_header.findIndex(
    //                (item) => item == "delete"
    //             );
    //             this.table_header.splice(table_index, 1);
    //          }
    //       } else {
    //          if (!this.table_header.includes("delete")) {
    //             this.table_header.push("delete");
    //             this.fields[7] = {
    //                key: "delete",
    //             };
    //          }
    //       }
    //    }
    // },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
  },
  created() {
    if (
      this.$route.name == "add-metacontent" ||
      this.$route.name == "edit-metacontent"
    ) {
      if (this.$route.name == "edit-metacontent") {
        this.fetchExpert(this.$route.params.id);
      }
    } else {
      this.fetchData("all");
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
  mounted() {},
};
